import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { theme } from "./materialui/theme";
import * as serviceWorker from "./serviceWorker";
import ThemeContextProvider from "./theme/themeContext";
import { IntlProvider } from "react-intl";

import messages_ja from "./translations/ja.json";
import messages_en from "./translations/en.json";
import messages_zh from "./translations/zh.json";
import messages_hi from "./translations/hi.json";
import messages_es from "./translations/es.json";
import messages_ar from "./translations/ar.json";

const messages = {
  ja: messages_ja,
  en: messages_en,
  zh: messages_zh,
  hi: messages_hi,
  es: messages_es,
  ar: messages_ar,
};

// ブラウザの言語をJavascriptから調べる
const language = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : navigator.language.split(/[-_]/)[0]; // language without region code

localStorage.setItem("lang", language);

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <ThemeContextProvider>
      <App theme={theme} />
    </ThemeContextProvider>
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
